@import "~antd/dist/antd.less";

.pet-card {
  border-radius: 7px;
  overflow: hidden;
  box-shadow: @card-shadow;

  /* image */
  & &-cover {
    position: relative;
  }
  & &-image {
    &.placeholder {
      background: #eaeaea;
    }
    &:after {
      content: "";
      width: 0;
      border-bottom: 2px solid @primary-color;
      position: absolute;
      bottom: 0;
      transition: all 300ms ease-in-out;
    }
  }
  &:hover &-image {
    &:after {
      width: 100%;
    }
    img {
      transform: scale(1.1);
      transition: all 300ms ease-in-out;
    }
  }

  /* contents */
  & &-body {
    padding: 12px 10px; /* was 24px */
  }

  & &-meta {
    text-align: center;
    margin: 0;
    clear: both;
    .ant-card-meta-description {
      color: rgba(
        0,
        0,
        0,
        0.5
      ); /* going the tiniest bit darker from .45 to .50 */
    }
  }

  & &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2px 0 8px 6px;
  }

  & &-name {
    font-size: 2rem;
    font-weight: 100;
  }

  & &-breed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & &-distance {
    display: inline;
  }

  & &-distance:after {
    content: " - ";
  }

  /* horizontal format - needs work below 375px - height of meta is taller than square image */
  &.horizontal {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: @screen-sm-min) {
      .ant-card-meta-title {
        padding-bottom: 7px;
      }
    }
  }

  &.horizontal &-cover {
    width: 40%;
    height: auto;
  }

  &.horizontal &-body {
    width: 60%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
  }

  &.horizontal &-header {
    justify-content: center;
    margin: unset;
  }

  &.horizontal &-meta {
    margin-bottom: 1rem;
    @media (min-width: @screen-sm-min) {
      .ant-card-meta-description {
        font-size: 16px;
        line-height: 2rem;
      }
    }
  }

  &.horizontal &-name {
    border-bottom: 1px solid @primary-color;
  }
}

@media (max-width: 375px) {
  .pet-card {
    font-size: 12px;

    &.horizontal &-meta {
      margin-bottom: 0.5rem;
    }

    &.horizontal &-body {
      padding: 0;
    }
  }
}

@primary-color: #b92020;@link-color: #b92020;@border-radius-base: 5px;