@import "~antd/dist/antd.less";

.slick {
  &-prev,
  &-next {
    position: absolute;
    top: calc(50% - 10px);
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 20px;
    color: gray;
    vertical-align: 0;

    &:hover {
      color: @primary-color;
    }
  }

  &-prev {
    left: 0;
    margin-left: -20px;
  }

  &-next {
    right: 0;
    margin-right: -20px;
  }
}

.slider-content-wrapper {
  width: 100%;

  &.arrows {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
  }
}

@primary-color: #b92020;@link-color: #b92020;@border-radius-base: 5px;