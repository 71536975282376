@import "~antd/dist/antd.less";

/* antd global body is 14px */
@media (max-width: @screen-xs) {
  body,
  html {
    font-size: 12px;
  }
}

@media (min-width: @screen-xs + 1px) and (max-width: @screen-sm-max) {
  body,
  html {
    font-size: 13px;
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  body,
  html {
    font-size: 14px;
  }
}

@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
  body,
  html {
    font-size: 15px;
  }
}

@media (min-width: @screen-xl-min) {
  body,
  html {
    font-size: 16px;
  }
}

.search-results {
  width: 100%;
  padding: 0 2%;
}

.center-contents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-size {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none;
}

@primary-color: #b92020;@link-color: #b92020;@border-radius-base: 5px;