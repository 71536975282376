@import "~antd/dist/antd.less";

.home-adoptable-pets {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2%;

  & > a {
    margin-left: auto;
    align-self: flex-end;
    margin-right: 36px;
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 100;

    .species {
      font-weight: 500;
    }
  }

  .pet-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pet-card-grid-wrapper {
    width: 25%;
    padding: 16px;

    @media (max-width: @screen-md-max) {
      width: 33.3%;
    }

    @media (max-width: @screen-sm-max) {
      width: 50%;
    }
  }

  @media (max-width: @screen-xs-max) {
    .pet-card-grid-wrapper {
      padding: 8px;
    }

    .pet-card {
      .pet-card-header {
        justify-content: center;
        margin: 0;

        .ant-card-meta-avatar {
          display: none;
        }
      }
    }

    & > a {
      margin-right: 28px;
    }
  }
}

@primary-color: #b92020;@link-color: #b92020;@border-radius-base: 5px;