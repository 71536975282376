@import "~antd/dist/antd.less";

/* Note: needs to include ant-btn names in order to override default transition */
.chevron-hover-button.ant-btn {
  .chevron-icon.anticon {
    transition: all 300ms @ease-in-out;
    width: 0;
    opacity: 0;
  }
  &:hover .chevron-icon.anticon {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    opacity: 1;
  }
}

@primary-color: #b92020;@link-color: #b92020;@border-radius-base: 5px;